import { MuiTelInput } from "mui-tel-input";
import { success } from "../assets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";

export default function ChatBotForm({
  submitted,
  handleSubmit,
  reset,
  submitting,
  phone,
  setPhone,
  setCountry,
  startDate,
  setStartDate,
}) {
  if (submitted) {
    setTimeout(() => {
      reset(false);
    }, 5000);
  }
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const minTime = isToday(startDate)
    ? new Date()
    : setHours(setMinutes(new Date(), 0), 0);
  const maxTime = setHours(setMinutes(new Date(), 45), 23);

  return !submitted ? (
    <form
      onSubmit={handleSubmit}
      className="mt-1 w-full max-w-[394px] mx-auto flex flex-col gap-[13px] md:gap-[15px] "
    >
      <div className="flex  w-full justify-between gap-3 md:gap-5">
        <input
          className="py-[9px] px-[10px] text-[12px] w-full h-10 border border-[rgba(255,255,255,0.20)] outline-0 text-white bg-[#03050B]"
          required
          type="text"
          placeholder="Full name"
          id="first_name"
          name="first_name"
        />
      </div>
      <div className="field">
        <input
          className="py-[9px] px-[10px] text-[12px] w-full h-10 border border-[rgba(255,255,255,0.20)] outline-0 text-white bg-[#03050B]"
          required
          type="text"
          placeholder="Email"
          id="email"
          name="email"
        />
      </div>
      <div className="field">
        <MuiTelInput
          id="phone"
          value={phone}
          defaultCountry="GB"
          onChange={(val, details) => {
            setPhone(val);
            setCountry(details?.countryCode);
          }}
          className="w-full"
          placeholder="Phone number"
        />
      </div>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        className="w-full py-[9px] px-[10px] text-[12px] h-10 border border-[rgba(255,255,255,0.20)] outline-0 text-white bg-[#03050B]"
        placeholderText="Select Date and Time"
        showTimeSelect
        timeFormat="HH:mm"
        minDate={new Date()}
        minTime={minTime}
        maxTime={maxTime}
        timeIntervals={15}
        dateFormat="MMMM d, yyyy h:mm aa"
        timeCaption="time"
        id="date"
      />
      <div className="field">
        <textarea
          rows={2}
          className="py-[9px] px-[10px] text-[12px] w-full border border-[rgba(255,255,255,0.20)] outline-0 text-white bg-[#03050B]"
          type="text"
          placeholder="Reason for callback"
          id="note"
          name="note"
        />
      </div>
      <div className="w-full flex gap-3 md:gap-5 mx-auto justify-between mt-1">
        <button
          type="reset"
          className="w-full flex border border-[rgba(255,255,255,0.20)] outline-0 justify-center items-center px-[56px] text-[12px] py-[13px] bg-inherit rounded-[4px]"
        >
          Cancel
        </button>{" "}
        <button
          disabled={submitting}
          type="submit"
          className="w-full flex justify-center items-center px-[56px] text-[12px] py-[13px] bg-poppingBlue rounded-[4px]"
        >
          {submitting ? "Submitting" : "Submit"}
        </button>
      </div>
    </form>
  ) : (
    <div className="flex flex-col items-center justify-center gap-5 mt-[30%]">
      <img src={success} alt="Success" />
      <p className="text-[14px] text-white leading-[180%] max-w-[235px] mx-auto text-center">
        Call Request Successful, check your email for the call details
      </p>
    </div>
  );
}
