import { call, chat } from "../assets";

export const navLinks = [
  {
    label: "Products",
    links: [
      {
        label: "[AI] Platform",
        link: "/ai-platform",
      },
      {
        label: "Data Assistant",
        link: "/data-assistant",
      },
      {
        label: "PLLM",
        link: "/pllm",
      },
    ],
  },
  {
    label: "Solutions",
    links: [
      {
        label: "Retail & E-commerce",
        link: "/retail",
      },
      {
        label: "Fintech & Finance",
        link: "/fintech",
      },
      {
        label: "Marketing & Advertising",
        link: "/marketing",
      },
    ],
  },
  {
    label: "Resources",
    links: [
      {
        label: "Digital Analytics Support",
        link: "/digital-analytics-support",
      },
      {
        label: "Digital Analytics Training",
        link: "/digital-analytics-training",
      },
      // {
      //   label: "Guides",
      //   link: "/guides",
      // },
      {
        label: "Blog",
        link: "/blog",
      },
      // {
      //   label: "Events",
      //   link: "/events",
      // },
    ],
  },
];

export const footerLinks = [
  {
    label: "PRODUCT",
    links: [
      {
        label: "[AI] Platform",
        link: "/ai-platform",
      },
      {
        label: "Data Assistant",
        link: "/data-assistant",
      },
      {
        label: "PLLM",
        link: "/pllm",
      },
      {
        label: "Request a Demo",
        link: "/demo",
      },
    ],
  },
  {
    label: "COMPANY",
    links: [
      {
        label: "About us",
        link: "/company",
      },
      {
        label: "Contact Us",
        link: "/company#contact-us",
      },
      {
        label: "Careers",
        link: "/company#careers",
      },
      {
        label: "Faqs",
        link: "/#faqs",
      },
    ],
  },
  {
    label: "LEGAL",
    links: [
      {
        label: "Legal information",
        link: "/privacy",
      },
      {
        label: "Privacy",
        link: "/privacy",
      },
      {
        label: "Cookies",
        link: "/",
      },
      {
        label: "",
        link: "/",
      },
    ],
  },
  {
    label: "CONTACT",
    links: [
      {
        label: "info@analyticsintelligence.com",
        link: "mailto:info@analytics intelligence.com",
      },
      {
        label: "sales@analyticsintelligence.com",
        link: "mailto:sales@analytics intelligence.com",
      },
      {
        label: "support@analyticsintelligence.com",
        link: "mailto:support@analyticsintelligence.com",
      },
      {
        label: "careers@analyticsintelligence.com",
        link: "mailto:careers@analyticsintelligence.com",
      },
    ],
  },
];

export const questions = [
  "What is a RAG platform?",
  "Capabilities of Data Assitant and AI platorm?",
  "What solutions do you offer?",
];

export const aIPlatformQuestions = [
  "How does [AI] integrate data?",
  "What skills enhance AI assistant?",
  "How does KPI monitoring work?",
];

export const dataAssistantQuestions = [
  "What does Data Assistant do?",
  "How does Analytics Engine work?",
  "What is Data Language Model?",
];

export const pllmQuestions = [
  "How does PLLM secure data?",
  "How does DataSyntax improve privacy?",
  "What does DataSyntax represent?",
];

export const retailQuestions = [
  "How does [AI] boost retail marketing?",
  "What insights come from customer data?",
  "How are customers segmented and targeted?",
];

export const fintechQuestions = [
  "How does AI improve financial services?",
  "What tasks can be automated in fintech?",
  "How does the platform enhance decision-making?",
];

export const marketingQuestions = [
  "How does AI enhance marketing strategies?",
  "What alerts can be set for campaign performance?",
  "How are insights shared within the team?",
];

export const digitalAnalyticsQuestions = [
  "How does [AI] optimize digital performance?",
  "What is included in insight reporting?",
  "How do we ensure data accuracy?",
];

export const digitalAnalyticsTrainingQuestions = [
  "What does the digital analytics training cover?",
  "How can I master Adobe Analytics?",
  "What skills are taught in prompt engineering?",
];

export const aboutUsQuestions = [
  "What is the mission of [AI] Analytics Intelligence?",
  "How did [AI] Analytics Intelligence begin?",
  "What core values guide [AI] Analytics Intelligence?",
];

export const privacyPolicyQuestions = [
  "How is personal information collected and used?",
  "What security measures protect personal information?",
  "How does [AI] Analytics comply with privacy regulations?",
];

export const actions = [
  {
    action: "chat",
    desc: "Instant message with our help assistant [AI]Chat",
    icon: chat,
  },
  {
    action: "call",
    desc: "Schedule a Call with a customer care Representative",
    icon: call,
  },
];

export const countries = [
  "Country",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of The",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and The South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const timezones = ["Time zone", "UTC", "GMT", "PST", "WAT"];

export const prefilledQuestions = (pathname) => {
  switch (pathname) {
    case "/ai-platform":
      return aIPlatformQuestions;
    case "/data-assistant":
      return dataAssistantQuestions;
    case "/pllm":
      return pllmQuestions;
    case "/retail":
      return retailQuestions;
    case "/marketing":
      return marketingQuestions;
    case "/fintech":
      return fintechQuestions;
    case "/digital-analytics-support":
      return digitalAnalyticsQuestions;
    case "/digital-analytics-training":
      return digitalAnalyticsTrainingQuestions;
    case "/company":
      return aboutUsQuestions;
    case "/privacy":
      return privacyPolicyQuestions;

    default:
      return questions;
  }
};
